<template>
    <div class="spinner">
        <div :style="{'background-color':backgroundColor}" class="bounce1"></div>
        <div :style="{'background-color':backgroundColor}" class="bounce2"></div>
        <div :style="{'background-color':backgroundColor}" class="bounce3"></div>
    </div>
</template>

<script>
    export default {
        name: "Spinner",
        props:{
            backgroundColor: {
                type: String,
                default: 'rgba(62,62,62,0.23)'
            }
        }
    }
</script>

<style scoped lang="scss">
    .spinner {
        margin: 15px auto;
        width: 70px;
        text-align: center;
    }

    .spinner > div {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .spinner .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .spinner .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
        0%, 80%, 100% { -webkit-transform: scale(0) }
        40% { -webkit-transform: scale(1.0) }
    }

    @keyframes sk-bouncedelay {
        0%, 80%, 100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        } 40% {
              -webkit-transform: scale(1.0);
              transform: scale(1.0);
          }
    }
</style>