<template>
    <div class="search" :class="{'search--input-active': inputActive, 'search--fixed': fixedSearch}">

        <p class="search__input control has-icons-right">
            <input class="input" v-model="query" type="search" placeholder="Search..." @keyup="search" />
            <span class="icon is-small is-right" @click="toggleSearch">
                <i class="svg-icon svg-icon--search">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M337.509 305.372h-17.501l-6.571-5.486c20.791-25.232 33.922-57.054 33.922-93.257C347.358 127.632 283.896 64 205.135 64 127.452 64 64 127.632 64 206.629s63.452 142.628 142.225 142.628c35.011 0 67.831-13.167 92.991-34.008l6.561 5.487v17.551L415.18 448 448 415.086 337.509 305.372zm-131.284 0c-54.702 0-98.463-43.887-98.463-98.743 0-54.858 43.761-98.742 98.463-98.742 54.7 0 98.462 43.884 98.462 98.742 0 54.856-43.762 98.743-98.462 98.743z"></path></svg>
                </i>
                <i class="svg-icon svg-icon--close">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"/></svg>
                </i>
            </span>
        </p>

        <div class="search__results dropdown" :class="{'is-active': this.active}">
            <div class="search-results">
                <div class="dropdown-menu" id="dropdown-menu2" role="menu">

                    <div class="dropdown-content" v-if="loading || (results && results.length)">
                        <spinner v-show="loading"/>
                        <ul class="search-results__list">
                            <li v-show="!loading" v-for="(result, key) in results">
                                <template v-if="key < limit">
                                    <a :href="result.url" class="dropdown-item search-result">
                                        <span class="search-result__title">{{ result.title }}</span>
                                        <span :class="['search-result__tag--'+result.searchable_type]"
                                              class="tag search-result__tag"
                                              data-config-id="card-badge">
                                            {{ result.searchable_type }}
                                        </span>
                                    </a>
                                    <hr class="dropdown-divider" v-if="key < (results.length - 1)">
                                </template>
                            </li>
                        </ul>
                        <a :href="fullSearchUrl" v-if="results.length > limit" class="dropdown-item search-results__cta">View all ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import Spinner from "./Spinner";
    export default {
        name: "Search",
        components: {
            Spinner
        },
        computed:{
            fullSearchUrl(){
                return '/search/'+this.query;
            },
        },
        data(){
            return {
                active: false,
                inputActive: false,
                query: '',
                minQueryLength: 3,
                limit: 5,
                loading: false,
                results: [],
                awaitingSearch: false,
                fixedSearch: false
            }
        },
        methods: {
            async search(){
                if(this.query.length < this.minQueryLength){
                    this.active = false;
                    this.loading = false;
                    this.results = [];
                    return;
                }

                this.active = true;
                this.loading = true;

                if(this.awaitingSearch){
                    return;
                }
                setTimeout( async () => {
                    this.results = await this.apiSearch(this.query);

                    if(this.results.length < this.limit){
                        const thirdPartyResults = await this.thirdPartySearch(this.query);
                        this.results = this.results.concat(Object.values(thirdPartyResults));
                    }
                    this.loading = false;
                    this.awaitingSearch = false;
                }, 1000);
            },
            async apiSearch(query)
            {
                return this.$http.get(window.baseUrl + "/api/search/"+query)
                    .then(response => {
                        if(response.data.success){
                            return response.data.data;
                        }
                        return [];
                    });

            },
            async thirdPartySearch(query)
            {
                return this.$http.get(window.baseUrl + "/api/search/thirdparty/artists/"+query)
                    .then(response => {
                        if(response.data.success){
                            return response.data.data;
                        }
                        return [];
                    });
            },
            toggleSearch()
            {
                if(this.$mq !== 'desktop'){
                    this.fixedSearch = !this.fixedSearch;
                }
                this.inputActive = !this.inputActive;

                if(this.inputActive){
                    $('.search__input input')[0].focus();
                }
                else{
                    this.query = '';
                    this.active = false;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '~@/variables';
    @import '~@/helpers/mixins';
    .search{
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        &--fixed{
            position: fixed;
            width: 100%;
            height: 100vh;
            left: 0;
            top: 0;
            z-index: 100;
            background: rgba($grey-borders, 0.75);

            .search__toggle-fixed{
                display: inline-block;
            }

            .input{
                border-radius: 0;
                padding: 30px;
            }
            .search__input{
                .svg-icon--search{
                    display: none;
                }
                .svg-icon--close{
                    display: inline-block;
                }
                .icon{
                    height: 100%;
                    right: 15px;
                    pointer-events: all!important;
                }
            }

        }

        &__toggle-fixed{
            margin-left: auto;
            display: none;
            svg{
                fill: $grey-copy-light;
            }
        }

        .dropdown, .dropdown-menu{
            width: 100%;
        }

        .dropdown-item{
            padding-right: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            white-space: normal;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .input{
            border: none;
        }
        &__input{
            @include min-tablet{
                width: 0%;
                min-width: 32px;
                transition: min-width $transition-time;
            }
            .icon{
                pointer-events: all;
                cursor: pointer;
                svg{
                    fill: $white;
                }
            }
            .svg-icon--close{
                display: none;
            }
            .input{
                opacity: 0;
                transition: opacity $transition-time;
            }
        }
    }
    .search--input-active{
        .search__input{
            min-width: 100%;
            .icon{
                pointer-events: none;
            }
            svg{
                fill: $grey-copy-light;
            }
            .input{
                opacity: 1;
            }
        }
    }
    .search-results__cta{
        color: $pink;
        text-align: center;
        justify-content: center!important;
    }
    .search-result__title{
        padding-right: 10px;
    }
    .dropdown-divider{
        margin: 0;
    }
    .dropdown-content{
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
    }
</style>
