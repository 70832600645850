require('./bootstrap');

import mediumZoom from 'medium-zoom';
mediumZoom('[data-zoomable]');

import Vue from 'vue';

// Cash
import cash from 'cash-dom';
window.$ = cash;

// Responsive breakpoints
import VueMq from 'vue-mq';

Vue.use(VueMq, {
    breakpoints: { // default breakpoints - customize this
        mobile: 400,
        tablet: 600,
        desktop: 992,
    }
});

// Axios
import axios from 'axios';
window.axios = axios;
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

// Vee Validate
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, is, is_not, min, confirmed } from 'vee-validate/dist/rules';
extend('email', email);
extend('is', is);
extend('is_not', is_not);

extend('min', {
    ...min,
    message: "The fields needs to contain at least {length} characters"
});

extend('required', {
    ...required,
    message: field => field + " is required"
});

extend('confirmed', {
    ...confirmed,
    message: field => field + " does not match"
});

extend('terms', {
    validate: value => {
        return value;
    }, // the validation function
    message: "Please accept the terms and conditions" // the error message
});

extend('eventRequired', {
    validate: value => {
        return typeof value.title !== 'undefined';
    }, // the validation function
    message: "Please choose a gig" // the error message
});

extend('itemRequired', {
    validate: value => {
        return typeof value.id !== 'undefined';
    }, // the validation function
    message: (field) => field + " is required" // the error message
});

extend('searchResultRequired', {
    validate: value => {
        return typeof value.title !== 'undefined';
    }, // the validation function
    message: (field) => field + " is required" // the error message
});


extend('url', {
    validate: value => {
        let pattern = new RegExp('^(https?:\\/\\/)'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(value);
    }, // the validation function
    message: (field) => field + " needs to be a valid URL" // the error message
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Moment
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
Vue.use(VueMoment, {
    moment,
});

// Pluralize
import pluralize from 'pluralize';
Vue.filter('pluralize', function (value, number) {
    return pluralize(value, number)
});


// Custom mixins
// inject a handler for `myOption` custom option
Vue.mixin({
    methods: {
        isUserLoggedIn()
        {
            return typeof window.gigulous.userId !== 'undefined' && window.gigulous.userId;
        }
    }
});

window.Vue = Vue;
// Custom files
require('./partials/header');

if(('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
    window.isTouch = true;
}else {
    window.isTouch = false;
}
