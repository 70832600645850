import Search from "../components/Search";

const app = new Vue({
    el: '.header',
    data() {
        return {
            menuActive: false,
            userMenuActive: false
        }
    },
    methods: {
        toggleMenu(){
            this.menuActive = !this.menuActive;
        },
        toggleUserMenu()
        {
            this.userMenuActive = !this.userMenuActive;
        },
    },
    components: {
        Search
    },
    mounted() {

    }
});
